<template>
  <div>
    <c-table
      ref="table"
      title="내부심사 체크리스트"
      :columns="grid.columns"
      :data="checklistResult"
      :gridHeight="gridHeight"
      :merge="grid.merge"
      :editable="editable&&!disabled"
      :isExcelDown="false"
      :filtering="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="saiInternalActionChecklistResultId"
    >
      <!-- @innerBtnClicked="innerBtnClicked" -->
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable&&!disabled" 
            label="삭제" icon="remove" 
            @btnClicked="deleteChecklist" />
          <c-btn 
            v-if="editable&&!disabled" 
            label="추가" icon="add" 
            @btnClicked="addChecklist" />
          <c-btn 
            v-if="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="checklistResult"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveChecklist"
            @btnCallback="saveChecklistCallback" />
        </q-btn-group>
      </template>
      <template slot="suffixTitle">
        <template v-if="hasTargetDepts">
          <font class="text-negative" style="font-size:0.9em;font-weight:500;">
            {{$language('※ 피심사팀을 선택하세요.')}}
          </font>
          <div class="internal-action-checklist-header-input">
            <q-chip
              v-for="(dept, idx) in options"
              outline square
              :key="idx"
              :removable="false"
              :color="dept.selected ? 'primary' : 'grey-6'"
              text-color="white"
              icon="memory"
              :selected.sync="dept.selected"
              :label="dept.codeName"
              v-model="dept.check"
              @update:selected="state => selectedDept(state, dept, idx)"
            >
            </q-chip>
            <!-- <c-select
              :comboItems="options"
              type="none"
              itemText="codeName"
              itemValue="code"
              name="filter"
              label=""
              v-model="filter"
            ></c-select> -->
          </div>
        </template>
        <template v-else>
          <font class="text-negative" style="font-size:0.9em;font-weight:500;">
            {{$language('※ 수행계획을 추가 후 저장하세요.')}}
          </font>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'internal-action-checklist',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiInternalActionId: '',
      }),
    },
    action: {
      type: Object,
      default: () => ({
        saiInternalActionId: '',  // 내부심사 수행계획서 일련번호
        plantCd: '',  // 사업장코드
        actionName: '',  // 실시계획서명
        actionStartDate: '',  // 심사시작일
        actionEndDate: '',  // 심사종료일
        period: [],
        saiInternalActionStepCd: '',  // 내부심사 수행계획서 진행상태
        saiInternalActionTypeCd: '',  // 심사구분
        auditPurposeScope: '',  // 심사 목적 및 범위
        auditPreRequire: '',  // 심사 준비 요청사항
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        reviewUserId: '', // 검토자
        approvalUserId: '', // 승인자
        regDtStr: '', 
        regUserName: '',  // 등록자
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        actionPlans: [], // 실시계획
        deleteActionPlans: [], // 실시계획 [삭제]
        targetDepts: [], // 피심사팀
        deleteTargetDepts: [], // 피심사팀 [삭제]
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      filter: null,
      selectedSchedule: [],
      grid: {
        merge: [
          { index: 0, colName: 'saiInternalAuditClassName' },
          { index: 1, colName: 'saiInternalAuditClassNameGubun' },
        ],
        columns: [
          {
            name: 'saiInternalAuditClassName',
            field: 'saiInternalAuditClassName',
            label: 'ISO 구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'systemElementsName',
            field: 'systemElementsName',
            label: '관련표준',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          // {
          //   name: 'sortOrder',
          //   field: 'sortOrder',
          //   label: '순번',
          //   align: 'center',
          //   style: 'width:30px',
          //   sortable: false,
          // },
          {
            name: 'itemName',
            field: 'itemName',
            label: '심사 항목',
            align: 'left',
            style: 'width:450px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'aimMatter',
            field: 'aimMatter',
            label: '비고',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'html',
          },
        ],
        data: [],
        height: '780px',
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    checklistResult() {
      let _data = [];
      if (this.hasTargetDepts) { // 저장된 피심사팀이 존재하는지?
        // && this.filter) { // 선택한 피심사팀이 있는지?
        let _targetDept = this.$_.find(this.targetDepts, { saiInternalActionTargetDeptId: this.filter })
        _data = _targetDept ? _targetDept.checklist : [];
      }
      return _data;
    },
    targetDepts() {
      return this.$_.filter(this.action.targetDepts, (dept) => {
        return dept.editFlag !== 'C'
      })
    },
    hasTargetDepts() {
      return this.targetDepts && this.targetDepts.length > 0
    },
    options() {
      let _options = [];
      if (this.targetDepts && this.targetDepts.length > 0) {
        let idx = 0;
        this.$_.forEach(this.targetDepts, item => {
          _options.push({
            code: item.saiInternalActionTargetDeptId,
            codeName: `${item.deptName}`,
            selected: (idx == 0 ? true: false),
          })
          idx++;
        })
      }
      return _options;
    },
    gridHeight() {
      return String(this.contentHeight - 130) + 'px';
    },
  },
  watch: {
    'action.targetDepts': {
      handler: function () {
        if (this.action.targetDepts && this.action.targetDepts.length > 0) {
          let _idx = this.$_.findIndex(this.action.targetDepts, { saiInternalActionTargetDeptId: this.filter });
          this.filter = _idx === -1 ? this.action.targetDepts[0].saiInternalActionTargetDeptId : this.action.targetDepts[_idx].saiInternalActionTargetDeptId
        } else {
          this.filter = null;
        }
      },
      deep: true,
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.internal.action.checklistResult.list.url;
      this.saveUrl = transactionConfig.sai.internal.action.checklistResult.save.url;
      this.deleteUrl = transactionConfig.sai.internal.action.checklistResult.delete.url;
      // code setting
      // list setting
      if (this.action.targetDepts && this.action.targetDepts.length > 0) {
        let _idx = this.$_.findIndex(this.action.targetDepts, { saiInternalActionTargetDeptId: this.filter });
        this.filter = _idx === -1 ? this.action.targetDepts[0].saiInternalActionTargetDeptId : this.action.targetDepts[_idx].saiInternalActionTargetDeptId
      } else {
        this.filter = null;
      }
    },
    getChecklist() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        saiInternalActionTargetDeptId: this.filter
      }
      this.$http.request((_result) => {
        let _targetDept = this.$_.find(this.targetDepts, { saiInternalActionTargetDeptId: this.filter })
        this.$set(_targetDept, 'checklist', _result.data)
      },);
    },
    /* eslint-disable no-unused-vars */
    deleteChecklist() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '삭제할 행을 지정하세요.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let _deleteData = this.$_.filter(selectData, (item) => {
              return item.editFlag !== 'C'
            })

            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(_deleteData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              let _targetDept = this.$_.find(this.targetDepts, { saiInternalActionTargetDeptId: this.filter })
              this.$_.forEach(selectData, item => {
                _targetDept.checklist = this.$_.reject(_targetDept.checklist, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addChecklist() {
      this.popupOptions.title = '체크리스트'; 
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/internalAudit/base/internalChecklistPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeChecklistPopup;
    },
    closeChecklistPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let _data = [];
        let _targetDept = this.$_.find(this.targetDepts, { saiInternalActionTargetDeptId: this.filter })
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(_targetDept.checklist, { saiInternalAuditChecklistId: item.saiInternalAuditChecklistId }) === -1) {
            _data.push({
              saiInternalActionChecklistResultId: uid(),  // 내부심사 수행계획서 체크리스트 일련번호
              saiInternalActionTargetDeptId: this.$_.clone(this.filter),  // 내부심사 피심사팀 일련번호
              saiInternalAuditChecklistId: item.saiInternalAuditChecklistId,  // 내부감사 체크리스트 항목 일련번호
              saiInternalAuditChecklistSystemId: item.saiInternalAuditChecklistSystemId,
              saiInternalAuditClassNameGubun: item.saiInternalAuditClassName + '/' + item.systemElementsName,
              saiInternalAuditClassName: item.saiInternalAuditClassName,  // ISO 구분
              systemElementsName: item.systemElementsName,  // 시스템 요소
              itemName: item.itemName,  // 내부심사 항목
              aimMatter: item.aimMatter,  // 내부심사 시 착안 사항
              saiInternalAuditResultCd: 'SAR0000001',  // 내부심사 결과 양호로 고정
              observation: '',  // 관찰사항
              sortOrder: item.sortOrder,  // 순번
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
        _targetDept.checklist = this.$_.concat(_data, _targetDept.checklist)
      }
    },
    saveChecklist() {
      if (this.$comm.validTable(this.grid.columns, this.checklistResult)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '저장하시겠습니까?',  
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveChecklistCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getChecklist();
    },
    selectedDept(state, dept, idx) {
      this.filter = dept.code;
      if (state) {
        this.options.forEach((item, index) => {
          if (idx !== index) {
            this.$set(item, 'selected', false)
          } else {
            this.$set(item, 'selected', true)
          }
        })
      } else {
        this.$set(this.options[idx], 'selected', true)
      }
    },
  }
};
</script>
<style lang="sass">
.internal-action-checklist-header-input
  float: right !important
  margin-top: -5px
  padding-right: 5px
  .customSelect
    padding-bottom: 0 !important
    .q-field__inner
      padding: 0 !important
</style>